import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import axios from "axios";

import '../styles/contact.css'
import * as constants from '../constants';


export default function ContactUs() {

    const navigate = useNavigate();

    const baseURL = process.env.REACT_APP_BASEURL;


    const [nameText,setNameText] = useState("")
    const [emailText,setEmailText] = useState("")
    const [messageText,setMessageText] = useState("")

    const [nameError,setNameError] = useState(false)
    const [emailError,setEmailError] = useState(false)
    const [messageError,setMessageError] = useState(false)

    const [submitted,setSubmitted] = useState(false)

    const linkClicked = (link) => {

        switch(link){
            case 'Instagram':
                openInNewTab('https://instagram.com');
                break;
            case 'LinkedIn':
                openInNewTab('https://linkedin.com');
                break;
            case 'YouTube':
                openInNewTab('https://youtube.com');
                break;
            case 'WhatsApp':
                openInNewTab('https://whatsapp.com');
                break;


        }
        

    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    	if (newWindow) newWindow.opener = null
      };

      

    const submitClicked = () => {
        var error = false

        setNameError(false)
        setEmailError(false)
        setMessageError(false)

        if(nameText == ""){error = true;setNameError(true)}
        if(emailText == ""){error = true;setEmailError(true)}
        if(messageText == ""){error = true;setMessageError(true)}

        if(!error){
            setSubmitted(true)
            sendMessage()
        }
        else{

        }
    }

    const sendMessage = () =>
    {
        
        axios.post(baseURL+'routeenEnquiry',{
            recepient:constants.SUPPORT_EMAIL,
            name:nameText,
            email:emailText,
            message:messageText
        }).then((response) => {
            let resVar = response.data
            
            console.log("Email", resVar)
        })
    }


    return(
        <div className="contactScreen">

            <div className="contactScreenContainer">

                <img className="contactImg" src="assets/contactImg1.png"/>

                <div className="contactColumn">
                    <span className="contactTitle">Contact Us</span>
                    {!submitted && <div className="contactInputs">
                        <input className={`inputContact ${nameError?"error":""}`} type="text" placeholder="Full Name" value={nameText} onChange={(e) => setNameText(e.target.value)}></input>
                        <input className={`inputContact ${emailError?"error":""}`} type="text" placeholder="Email" value={emailText} onChange={(e) => setEmailText(e.target.value)}></input>
                        <input className={`inputContact ${messageError?"error":""}`} type="text" placeholder="Message" value={messageText} onChange={(e) => setMessageText(e.target.value)}></input>
                    </div>}
                    {submitted &&<div className="contactInputSubmitted">
                        <img className="contactSubmittedImg" src="/assets/submittedTick.png"/>
                        <span>Thank you! A representative will get in touch with you soon</span>
                    </div>}
                    <button className="contactButton" onClick={submitClicked}>Send Message</button>

                </div>

                <div className="contactColumn2">
                    <span className="contactSubTitle">For Support:</span>
                    <span>{constants.SUPPORT_EMAIL}</span>


                    <div className="contactRow">
                        <img className="contactIcon" src="assets/contactIG.png" onClick={() => linkClicked('Instagram')}/>
                        <img className="contactIcon" src="assets/contactLI.png" onClick={() => linkClicked('LinkedIn')}/>
                        <img className="contactIcon" src="assets/contactYT.png" onClick={() => linkClicked('YouTube')}/>
                        <img className="contactIcon" src="assets/contactWA.png" onClick={() => linkClicked('WhatsApp')}/>
                    </div>
                </div>

            </div>

        </div>
    )
}



import React, { useEffect, useState } from 'react';
import '../../styles/home.css'



export default function ReviewSlider() {

    

const reviewArray = [
    {rating:5,name:"John Doe", subtext: "Lkfei ewrwr", body:"Maecenas heud erkmut dui vitae augue malesuada iaculis eget eget ex."},
    {rating:3,name:"Jane Smith", subtext: "Stmwef wee", body:"Maecenas tincidunt ligula non ex auctor finibus. Nullamut dui vitae augue malesuada iaculis eget eget ex.Maecenas tincidunt ligula non ex auctor finibus. Nullamut dui vitae augue malesuada iaculis eget eget ex."},
    {rating:4,name:"Chester Royal", subtext: "swaew fiww", body:"Mue malesuada iaculis eget eget ex."},
    {rating:2,name:"Thomas Sandicot", subtext: "Relit icha", body:"Maecenas tincidunt ligula non ex auctor finibus. Nullamut dui vitae augue malesuada iaculis eget eget ex.Maecenas tincidunt ligula non ex auctor finibus. Nullamut dui vitae a."}
]
        
    const [reviews,setReviews] = useState(reviewArray)
    const [index,setIndex] = useState(0)

    const getStars = () => {

        var stars = []

        if(reviews.length>0)
        {
            
            for(var i=0;i<reviews[index].rating;i++)
            {
                stars.push(<img className='reviewStar' src='/assets/Star.png'/>)
            }
        }

        return stars
    }

    const getDots = () => {

        var dots = []

        for(var i=0;i<reviews.length;i++)
        {
            dots.push(<span class={`dot ${index==i?'filled':''}`}></span>)
        }

        return dots
    }

    const decrementIndex = () =>{
        console.log(index-1)
        setIndex(index-1)
    }

    const incrementIndex = () =>{
        console.log(index+1)
        setIndex(index+1)
    }


    return(
    <div className='reviewSlider'>
        
        <div className='reviewHorizontal'>
            
        <div className='reviewArrowContainer'><img className='reviewArrow' src='assets/leftArrow.png' onClick={decrementIndex} hidden={index == 0}/></div>

            <div className='reviewContainer'>

                <div className='reviewStars'>
                   {getStars()}
                </div>

                <span className='reviewBody'>{reviews[index].body}</span>

                <div className='reviewBottomSection'>

                    <img className='reviewImage' src='assets/reviewImg.png'/>
                    <div className='reviewColumn'>
                        <span className='reviewName'>{reviews[index].name}</span>
                        <span className='reviewSubtext'>{reviews[index].subtext}</span>
                    </div>
                </div>

            </div>


            <div className='reviewArrowContainer'><img className='reviewArrow' src='assets/rightArrow.png' onClick={incrementIndex} hidden={index==reviews.length-1}/></div>

        </div>

        <div className='reviewDotRow'>
            {getDots()}
        </div>

    </div>
    )
}
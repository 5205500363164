import { getAuth,signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import '../../../styles/dashboard.css'
import * as constants from '../../../constants'

export default function NoClasses({type,hasPending,routeChanged, isTutor}) {

    const navigate = useNavigate();

    const isLessons = (type==="unscheduled" || type==="scheduled")
    const [title,setTitle] = useState("No Scheduled Classes")
    const [subTitle,setSubTitle] = useState("You have no classes scheduled yet, discover our tutors and get started today!")

    useEffect(() => {

        if(type === "unscheduled")
        {
            setTitle("No Pending Classes")
            setSubTitle("You have no unscheduled classes remaining, book more today!")
        }
        else if(type === "packs")
        {
            setTitle("No Practice Packs")
            setSubTitle("Your tutor will asssign practice packs to you")
        }
        else if(type === "tests")
        {
            setTitle("No Practice Tests")
            setSubTitle("Start a practice test for it to appear here")
        }
        else if(type === "completedTests")
        {
            setTitle("No Complete Tests")
            setSubTitle("Complete a practice test for it to appear here")
        }

        if(isTutor){
            setSubTitle("You have no classes scheduled yet.")
        }

    },[])

    const discover = () => {
        if(constants.MULTIPLE)
        {
            routeChanged('discover')
            navigate(`../discover`, { replace: true });
        }
        else{
            routeChanged('tutorProfile')
            navigate(`../tutorProfile`, { replace: true });
        }
    }

    return(
        <div className="noClasses">
            
            <span className="noClassesTitle">{title}</span>
            <span className="noClassesSubTitle">{subTitle}</span>

            {(!isTutor && isLessons) && <div className="noClassesBtns">
                <button className="noClassesBtn" onClick={discover}>{constants.MULTIPLE?"Discover Tutors":"View Tutor"}</button>
                {hasPending && <button className="noClassesBtn">Schedule Pre Booked Classes</button>}
            </div>}
        </div>
    )
}



import React, { useEffect, useRef, useState } from 'react';
import '../../styles/pay.css'
import axios from "axios";
import PaymentComponent from '../General/PaymentComponent';

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

export default function PaymentPopUp({type,closePopUp}) {

    const stripePromise = loadStripe('pk_live_51LuBnCSC11nmIG705kcgWxVYMHgJYiwWZFf705p99We05ciAoXVssmmdyxAtjCmwetBnl13tyBduxIz2R22scF4Y008v5QaBcD',
    // {stripeAccount:'acct_1LuK1cC7dQuPBvhw'}
    );

    const baseURL = process.env.REACT_APP_BASEURL;

    const [setupIntentID, setIntentID] = useState("")
    const [checkingPayment,setCheckingPayment] = useState(false)
    const [options, setOptions] = useState(null)
    const [showPaySuccess,setShowPaySuccess] = useState(false)

    const appearance = {
        theme: 'flat',
        variables:{
            
        }
        
      };

      useEffect(() => {
            getCustomerStripeID()
        },[])


        
   
// #region SHOW COMPONENTS
 

// #endregion

   
// #region WEB REQUESTS
 

    const getCustomerStripeID = async() => 
    {

        let user = JSON.parse(localStorage.getItem("user"))
        let uid = user.uid  

        return new Promise(async (resolve, reject) => {
            axios.get(baseURL+'getWLTutorCustomersStripeID',{
            
                params:{
                    authID:uid,
                }

            }).then((response) => {
                let resVar = response.data
                
                let cusID = resVar.customerID
                createPaymentIntent(cusID)
            })
        })
    }

    const createPaymentIntent = (cusID) => {


        axios.get(baseURL+'createPaymentIntent',{
            params:{
                customer: cusID,
                amount: type==="starter"?5520:7200,
                account: 'acct_1LuBnCSC11nmIG70'
            }
        }).then( (response) => {
        
        const data = response.data;
        
        let setUpID = data.paymentIntent.client_secret
        console.log("Setup ID",setUpID)

        

        setOptions({clientSecret:setUpID,appearance:appearance})
            
        setIntentID("")
        setIntentID(setUpID)
        
        });

    }

    const addCreditsToAccount = () => {

        let user = JSON.parse(localStorage.getItem("user"))
        let uid = user.uid  
  

        axios.get(baseURL+'classesPurchased',{
            params:{
                tutor: uid,
                amountClasses: type==="starter"?30:50
            }
        }).then( (response) => {
        
            const data = response.data;
            
            let balance = data.balance
            localStorage.setItem("creditBalance",balance)

        });
      }

      
     
  

// #endregion

   
// #region BUTTONS CLICKED
const payClicked = () => {
    setCheckingPayment(true)
}

// #endregion

   
// #region OTHER
const stripeResponse = (res)=>
{
    if(res !== "Success"){setCheckingPayment(false)
            // setLoadingText("Checking card")
    }
    else{
        onSuccessfulPayment()
    }
}


const onSuccessfulPayment = async () => {

    console.log("SUCCESSFUL PAYMENT")
    addCreditsToAccount()
    closePopUp()

}

// #endregion




      


      
  
  
  

    return (
        <div className='paymentShadow'>
            <div className='paymentPopUp'>

                <div className='paymentRightAlign'><img className='paymentPopUpCancel' src='/assets/cross.png' onClick={closePopUp}/></div>
                
                
                <div className='paymentSectionBPU'>
                    
                    {!showPaySuccess && <div className='paymentComponentBPU'>                        
                        {/* <button className='bookingPopUpPayBtn' onClick={pay}>Click to complete your payment via Routeen’s Secure Payment Partner</button> */}
                        {(setupIntentID) && 
                            <Elements stripe={stripePromise} options={options} >
                                <PaymentComponent stripeResponse={stripeResponse}  submitClicked={checkingPayment} isSetup={false} savedCard ={{secret:options.clientSecret}}/>
                            </Elements>
                        }
                        {(!setupIntentID) && 
                            <div>Loading Payment....</div>
                        }
                    </div>}

                    {showPaySuccess && <div className='successfulPayment'>
                        Payment Confirmed
                    </div>}

                </div>
                <div className='paymentRightAlign'><button className='paymentPopUpSubmit' onClick={payClicked} disabled={!setupIntentID}>Pay</button></div>
            </div>
        </div>
    )
}


export const MULTIPLE = true

export const BUSINESS = "Routeen"//"Code Correctly"
export const LOGO = '/assets/logo.png'

export const SUPPORT_EMAIL = 'help@getrouteen.com'

export const TUTOR_ID = "fhO3R1HLkibYo2LsNy3wGlcAa763"
export const BIO = "Ivan is a software engineer with more than a decade of experience working for tech companies in the United States. Whether it’s frontend (web and mobile apps), backend (APIs), or cloud, you’ll learn the tools and skills necessary to become a software engineer via project based learning.";
export const LANGUAGES = ['English'];
export const SUBJECTS = ['Frontend','Backend','Cloud'];
export const NAME = "Ivan Valle";
export const PHOTO = '/assets/ivan.png'
export const PREFERENCE = "Everyone is welcome";
export const TITLE = "Software Engineer";
export const START_RATE = 25;
export const STRIPE = "acct_1L80HSF8EmGgjBYk";
export const VIDEO = "https://www.youtube.com/embed/R2pIutTspQA"

export const SOCIAL_MEDIA = [
    // {type:"Instagram",value:"https://www.instagram.com/caolangm/"}
];

export const OPTIONS = [
    {id:"1",amountLesson:1,duration:60,name:"Single Coding Class",subText:"4 - 6 students with project based learning",overallPrice:2500,pricePerLesson:2500},
    {id:"2",amountLesson:1,duration:30,name:"One on One Chat",subText:"One on one chat to get code help or talk about anything you want",overallPrice:2500,pricePerLesson:2500},
    {id:"3",amountLesson:1,duration:30,name:"Test Class",subText:"Test Class to see if working",overallPrice:50,pricePerLesson:50},

];
import '../../../styles/home.css'


export default function PlanTile({type,clicked}) {


    const showPerks = () => {

        var perkArray = []
        var perks;

        if(type === "Starter Pack"){perks = ['24/7 Human Support','Stripe Integrated Payments','30 Classes Available','Classes Valid For 3 Months']}
        if(type === "Professional"){perks = ['100 Classes Available','Valid For 3 Months','Personal Account Manager','Free Practise Platform*']}

        perks.forEach(perk => {
            perkArray.push(
                <div className='planTileRow'>
                    <img className='planTileCheck' src='/assets/checkCircle.png'/>
                    <span>{perk}</span>
                </div>
            )
        });

        return perkArray
    }


    return (
        <div className='planTileSection'>
        <div className={`planTile ${type}`}>

            <div className='planTileContent'>
                <div className='planTileLeft'>
                    <div className='planTileTitle'>{type}</div>
                    <span className='planTileCost'>{type==="Professional"?"$1.44":"$1.84"}</span>
                    <span>Per hour, billed for {type==="Professional"?"50":"30"} classes</span>
                </div>
                <div className='planTileLine'/>
                <div className='planTileRight'>
                    
                    {showPerks()}
                </div>
            </div>
            <button className='planTileBtn' onClick={clicked}>Purchase Now</button>
        </div>
        {type==="Professional" && <span>*Feature in development, please talk to a representative to know more</span>}
        </div>

    )
}
import React, { useEffect, useState } from 'react';
import '../../../styles/dashboard.css'
import Dropdown from '../../General/Dropdown';



export default function EditTutorQuestion({index,questionP,a1P,a2P,a3P,a4P,correctP,edit,deleted}) {

    const [chosenOption,setChosenOption] = useState(correctP)

    const [question,setQuestion] = useState(questionP)

    const [a1,setA1] = useState(a1P)
    const [a2,setA2] = useState(a2P)
    const [a3,setA3] = useState(a3P)
    const [a4,setA4] = useState(a4P)


    const options = [
        {id:"A", name:"A"},{id:"B", name:"B"},{id:"C", name:"C"},{id:"D", name:"D"}
    ]

    useEffect(() => {
        edit(index,{question:question,a1:a1,a2:a2,a3:a3,a4:a4,correct:chosenOption})
      }, [question,a1,a2,a3,a4,chosenOption]);


      useEffect(() => {
        setQuestion(questionP)
        setA1(a1P)
        setA2(a2P)
        setA3(a3P)
        setA4(a4P)
        setChosenOption(correctP)
      }, [questionP,a1P,a2P,a3P,a4P,correctP]);


    const optionChanged = (val) => {
        setChosenOption(val)      
    }
    
    const deleteFunc = () => {
        deleted(index)
    }

    return(

        <div className='editTutorQuestion'>

            <div className='etqDelete'><img className='binIcon' onClick={deleteFunc} src='/assets/cross.png'/></div>

            <input className='inputAnswer question' type="text" placeholder='Enter Question' value={question} onChange={(e) => setQuestion(e.target.value)}/>

            <div>Choose answer type</div>

            <div className='editAnswersGrid'>
                <div className='editAnswerRow'>A <input className='inputAnswer' type="text" placeholder='Enter Answer' value={a1} onChange={(e) => setA1(e.target.value)}/></div>
                <div className='editAnswerRow'>B <input className='inputAnswer' type="text" placeholder='Enter Answer' value={a2} onChange={(e) => setA2(e.target.value)}/></div>
                <div className='editAnswerRow'>C <input className='inputAnswer' type="text" placeholder='Enter Answer' value={a3} onChange={(e) => setA3(e.target.value)}/></div>
                <div className='editAnswerRow'>D <input className='inputAnswer' type="text" placeholder='Enter Answer' value={a4} onChange={(e) => setA4(e.target.value)}/></div>
            </div>

            <div className='editAnswerRow selectAnswer'>Correct answer <Dropdown data={options} onChange={optionChanged} value={chosenOption}/></div>

           
        </div>
    )
}
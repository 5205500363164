import { getAuth,signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import '../../styles/tutor.css'

export default function TutorPriceCell({bookClicked,priceOption,coupon}) {

    const getDiscountedPrice = () => {

        let initialPrice = priceOption.pricePerLesson/100

        if(coupon.type === "percantage")
        {
            let discountedPrice = initialPrice * ((100-coupon.discount)/100)
            return discountedPrice.toFixed(2)
        }
        else{
            let discount = (coupon.discount)/priceOption.amountLesson
            let discountedPrice = initialPrice - discount
            return discountedPrice.toFixed(2)
        }
    }

    const getDurationStr = () => {

        let minutes = priceOption.duration
        if(priceOption.duration%60 === 0){
            let hours = priceOption.duration/60
            return hours + (hours>1?" hours":" hour")
        }
        else{
            return minutes + " minutes"
        }

    }

    return(
        <div className="tutorPreferences tutorPriceCell">
            
            <div className="tutorPriceHeaderRow">
                <span className="tutorPriceTitle">{priceOption.name}</span>
                <div className="tutorPriceBubble">Popular</div>
            </div>

            <div className="tutorPriceColumn">
                <span>{priceOption.amountLesson}, {getDurationStr()} long</span>
                <span>{priceOption.subText}</span>
            </div>

            <div className="tutorPriceRow">
                <div className="tutorPricePair">
                    <span className="tutorPriceCurrency">$</span>
                    <span className="tutorPriceNumber">{priceOption.pricePerLesson/100}</span>
                </div>
                <span>per class</span>
                {coupon !== null && <div className="tprLine" />}
            </div>

            {coupon !==null && <div className="tutorPriceRow couponTPR">
                <div className="tutorPricePair">
                    <span className="tutorPriceCurrency">$</span>
                    <span className="tutorPriceNumber">{getDiscountedPrice()}</span>
                </div>
                <span>per class</span>
            </div>}

            <button className="tutorPriceBtn" onClick={bookClicked}>{coupon===null?"Book Now":"Book With Offer Applied"}</button>

        </div>
    )

}
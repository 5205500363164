import React, { useEffect, useState } from 'react';
import '../../styles/practice.css'

export default function AnswerOptionPP({clicked,answer,chosenOption}) {

    const getClassName = () => {

        var className = "answerPP"

        if(chosenOption !== null)
        {
            className += " disabled"

            if(chosenOption.id === answer.id && !answer.correct)
            {
                className += " wrongAnswer"
            }

            if(answer.correct)
            {
                className += " correctAnswer"
            }
        }

        
        
        return className

    }


    return(
        <div className={getClassName()} onClick={()=>clicked(answer)}>
            <div className='answerBox' />
            <span>{answer.value}</span>
        </div>
    )

}
import { getAuth,createUserWithEmailAndPassword,sendPasswordResetEmail,FacebookAuthProvider,GoogleAuthProvider ,signInWithPopup , RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword,signOut } from "firebase/auth";
import React, { useEffect, useState } from 'react';
import '../styles/login_register.css'
import { useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import { auth } from "..";
// import { UserObject } from "../customObjects/UserObject";

export default function LoginRegister() {

    const baseURL = process.env.REACT_APP_BASEURL;


    const navigate = useNavigate();
    const location = useLocation()

    const [showLogin,setShowLogin] = useState(true)
    const [forgotPassword,setForgotPassword] = useState(false)
    const [showOptions,setShowOptions] = useState(false)

    const [message,setMessage] = useState("")
    const [nameText,setNameText] = useState("")
    const [surNameText,setSurnameText] = useState("")
    const [emailText,setEmailText] = useState("")
    const [phoneText,setPhoneText] = useState("")
    const [passwordText,setPasswordText] = useState("")
    const [confirmPasswordText,setConfirmPasswordText] = useState("")

    const [loginError,setLoginError] = useState(false)
    const [loginErrorMessage,setLoginErrorMessage] = useState("")
    const [registerError,setRegisterError] = useState(false)
    const [registerErrorMessage,setRegisterErrorMessage] = useState("")


    useEffect(() => {

        if(auth.currentUser !== null)
        {
            navigate(`../`, { replace: true });
        }
        else{
            console.log("AUTCURRENT USER",auth)
        }

        
    },[])

    
    useEffect(() => {

        setLoginError(false)
        setRegisterError(false)
        
    },[showLogin,forgotPassword])



// #region DISPLAY COMPONENTS

    const changeDisplayedScreen = (forgotPasswordP) => {
            
        if(forgotPasswordP)
        {
            setForgotPassword(!forgotPassword)
        }
        else{
            setShowLogin(!showLogin)
            // setShowOptions(true)
        }
        

        setNameText("")
        setSurnameText("")
        setEmailText("")
        setPhoneText("")
        setPasswordText("")
        setConfirmPasswordText("")
        setMessage("")
    }
// #endregion

// #region BUTTONS CLICKED

    const optionClicked = () => {
        // setShowOptions(false)
    }

    const loginClicked = () => {
        login()
    }

    const registerClicked = () => {
        registerAccount()
    }

// #endregion

// #region AUTH

    const login = () => {
        
        setLoginError(false)
        signInWithEmailAndPassword(auth,  emailText, passwordText)
        .then((userCredential) => {
            // Signed in 

            const user = userCredential.user;
        })
        .catch((error) => {
            // An error happened.
            const errorMessage = error.message;
            console.log("ERROR", errorMessage)
            setLoginError(true)
            setLoginErrorMessage("Error: "+ errorMessage)
        });
    }


    const registerAccount = () => {

        setRegisterError(false)
        createUserWithEmailAndPassword(auth, emailText, passwordText)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;

            let userJSON = {uid:user.uid, name:nameText, email:emailText}
            localStorage.setItem("user",JSON.stringify(userJSON))

            addUserToDB(user.uid)
            
        })
        .catch((error) => {
            const errorMessage = error.message;
            console.log("ERROR", errorMessage)
            setRegisterError(true)
            setRegisterErrorMessage("Error: "+ errorMessage)
        });
    }
    

    const forgotPasswordSubmitted = () => {
        
        sendPasswordResetEmail(auth, emailText)
        .then(() => {
            setMessage("Email sent to reset password")
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("FORGOT ERR",errorCode,errorMessage)

            setLoginError(true)
            setLoginErrorMessage("Error: "+ errorMessage)
            
        });
    }
// #endregion

// #region WEB REQUESTS
    const addUserToDB = (uid) => {
        axios.post(baseURL+'createUserAccount',{
            
            uid:uid,
            name:nameText,
            email:emailText

        }).then((response) => {
            let resVar = response.data
            console.log("User", resVar)
        })
    } 
// #endregion


    

    



    // const googleClicked = () => {

    //     const provider = new GoogleAuthProvider();
        
    //     signInWithPopup(auth, provider)
    //       .then((result) => {
    //         // This gives you a Google Access Token. You can use it to access the Google API.
    //         const credential = GoogleAuthProvider.credentialFromResult(result);
    //         const token = credential.accessToken;
    //         // The signed-in user info.
    //         const user = result.user;

    //         console.log("Success GOOGLE", user)

    //         // ...
    //       }).catch((error) => {
    //         // Handle Errors here.
    //         const errorCode = error.code;
    //         const errorMessage = error.message;
    //         // The email of the user's account used.
    //         const email = error.customData.email;
    //         // The AuthCredential type that was used.
    //         const credential = GoogleAuthProvider.credentialFromError(error);
    //         // ...
    //         console.log("Google Error", error, credential)

    //       });
    // }


    return (

        <div className="splitScreen">
            <img className="loginImg" src="assets/loginimg.png"></img>



            {/* Login Screen */}
            {showLogin && <div className="formDiv flexColumnAuth"> 

                {!forgotPassword && <div className="flexColumnAuth width370 headerTextAuth gap63">

                <div className="flexColumnAuth gap6">
                    <span className="titleTextAuth">Sign in</span>
                    <span className="subTitleTextAuth">Welcome back!</span>
                </div>

                
                {!showOptions && <div className="flexColumnAuth">

                <div className="flexColumnAuth">
                    <span>Email</span>
                    <input className="inputAuth" type="text" placeholder="Enter email or user name" value={emailText} onChange={(e) => setEmailText(e.target.value)}></input>
                </div>

                <div className="flexColumnAuth">
                    <span>Password</span>
                    <input className="inputAuth" type="password" placeholder="Password" value={passwordText} onChange={(e) => setPasswordText(e.target.value)}></input>
                </div>

            
                <div className="flexRowAuth smallTextAuth">
                        <div className="flexRow bottomSectionAuth"><input type="checkbox"/><span>Remember me</span></div>
                        <span className="forgotPasswordBtn" onClick={() => changeDisplayedScreen(true)}>Forgot Password?</span>
                    </div>

                {loginError && <span className="loginError">{loginErrorMessage}</span>}
                <button className="buttonAuth" onClick={loginClicked}>Log In</button>

                <div className="flexRow bottomSectionAuth">
                    <span>Don't have an account yet?</span>
                    <span className="blueTextAuth" onClick={() => changeDisplayedScreen(false)}> Sign Up</span>
                </div>

                </div>}

                {showOptions && <div className="flexColumnAuth">
                        <div className="loginOptionButton" onClick={optionClicked}>
                            <img className="loginOptionImg" src="/assets/sms.png"/>
                            <span>Login with email</span>
                        </div>
                        {/* <div className="loginOptionButton" onClick={googleClicked}>
                            <img className="loginOptionImg" src="/assets/g-logo.png"/>
                            <span>Sign in with Google</span>
                        </div> */}
                        

                        <div className="flexRow bottomSectionAuth">
                        <span>Don't have an account yet?</span>
                        <span className="blueTextAuth" onClick={() => changeDisplayedScreen(false)}> Sign Up</span>
                    </div>

                    
                </div>}

                </div>}










                {forgotPassword && <div className="flexColumnAuth width370 headerTextAuth gap63">

                    <div className="flexColumnAuth gap6">
                        <span className="titleTextAuth">Forgot Password</span>
                        <span className="subTitleTextAuth">Send email to reset your password</span>
                    
                    </div>

                    
                    <div className="flexColumnAuth">

                    <div className="flexColumnAuth">
                        <span>Email</span>
                        <input className="inputAuth" type="text" placeholder="Enter email" value={emailText} onChange={(e) => setEmailText(e.target.value)}></input>
                    </div>

                    

                    <button className="buttonAuth" onClick={forgotPasswordSubmitted}>Send Email</button>

                    <span>{message}</span>

                    <div className="flexRow bottomSectionAuth">
                        <span>Click here to </span>
                        <span className="blueTextAuth" onClick={() => changeDisplayedScreen(true)}> Log In</span>
                    </div>

                    </div>

                    </div>}

            </div> }










            {/* Register Screen */}
           {!showLogin && <div className="formDiv flexColumnAuth"> 

                <div className="flexColumnAuth width370 headerTextAuth gap63 registerScreen">

                <div className="flexColumnAuth gap6">
                    <span className="titleTextAuth">Create a new account</span>
                    <span className="subTitleTextAuth">Experience learning like never before</span>
                </div>





                {!showOptions && <div className="flexColumnAuth">
                    <div className="flexColumnAuth">
                        <span>Name</span>
                        <div className="flexRowAuth">
                            <input className="inputAuth" type="text" placeholder="First Name" value={nameText} onChange={(e) => setNameText(e.target.value)}></input>
                            <input className="inputAuth" type="text" placeholder="Last Name" value={surNameText} onChange={(e) => setSurnameText(e.target.value)}></input>
                        </div>
                    </div>
                    
                    <div className="flexColumnAuth">
                        <span>Email</span>
                        <input className="inputAuth" type="text" placeholder="Enter email or user name" value={emailText} onChange={(e) => setEmailText(e.target.value)}></input>
                    </div>
                    
                    <div className="flexColumnAuth">
                        <span>Phone Number</span>
                        <input className="inputAuth" type="text" placeholder="Enter phone number" value={phoneText} onChange={(e) => setPhoneText(e.target.value)}></input>
                    </div>
                    
                    <div className="flexColumnAuth">
                        <span>Password</span>
                        <input className="inputAuth" type="password" placeholder="Password" value={passwordText} onChange={(e) => setPasswordText(e.target.value)}></input>
                    </div>
                    
                    <div className="flexColumnAuth">
                        <span>Confirm Password</span>
                        <input className="inputAuth" type="password" placeholder="Password" value={confirmPasswordText} onChange={(e) => setConfirmPasswordText(e.target.value)}></input>
                    </div>

                    <div className="flexRowAuth smallTextAuth">
                        <div className="flexRow bottomSectionAuth"><input type="checkbox"/><span>Remember me</span></div>
                        
                    </div>

                    {registerError && <span className="loginError">{registerErrorMessage}</span>}

                    <button className="headerTextAuth buttonAuth" onClick={registerClicked}>Register</button>

                    <div className="flexRow bottomSectionAuth">
                        <span>Already have an account?</span>
                        <span className="blueTextAuth" onClick={() => changeDisplayedScreen(false)}> Log In</span>
                    </div>

                    </div>}

                    {showOptions && <div className="flexColumnAuth">
                        <div className="loginOptionButton" onClick={optionClicked}>
                            <img className="loginOptionImg" src="/assets/sms.png"/>
                            <span>Register with email</span>
                        </div>
                        {/* <div className="loginOptionButton" onClick={googleClicked}>
                            <img className="loginOptionImg" src="/assets/g-logo.png"/>
                            <span>Register with Google</span>
                        </div> */}

                        <div className="flexRow bottomSectionAuth">
                        <span>Already have an account?</span>
                        <span className="blueTextAuth" onClick={() => changeDisplayedScreen(false)}> Log In</span>
                    </div>
                    </div>}


                </div>

            </div> }
        </div>
    )

}
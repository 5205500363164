import React, { useEffect, useState } from 'react';
import '../../styles/practice.css'
import ProgressBarPP from './ProgressBarPP';
import QuestionPP from './QuestionPP';
import axios from "axios";
import TestAnalytics from './TestAnalytics';

export default function PracticeTestPP({test}) {

    const questionArray = test.questions
    

    const findQuestion = (step) => {

        if(step<questionArray.length)
        {
            console.log("Qarr",questionArray)
            let question = questionArray[step].question
            setCurrentQuestion(question)
        }
        else{
            return{id:undefined,question:"",answers:[{id:"",correct:false,value:""},{id:"",correct:false,value:""},{id:"",correct:false,value:""},{id:"",correct:false,value:""}]}
        }

        
    }


    const [currentStep,setStep] = useState(test.progress)
    const [currentQuestion,setCurrentQuestion] = useState(null)
    const [answered,setAnswered] = useState(false)
    const baseURL = process.env.REACT_APP_BASEURL;

    
    useEffect(() => {
        if(currentQuestion === null){findQuestion(test.progress)}
    },[])

    


    const questionAnswered = (answer) => {
        setAnswered(true)
        questionArray[currentStep].answered = true
        questionArray[currentStep].answer = answer.id
        addResponseToDB()
    }

    const nextQuestionClicked = () => {
        setAnswered(false)
        findQuestion(currentStep+1)
        setStep(currentStep+1)
    }

    const addResponseToDB = () => {
        axios.post(baseURL+'answerQuestionStudentTest',{
            testID:test.id,
            questionNum:currentStep+1,
            answers:questionArray,
            complete:currentStep===(questionArray.length-1)

        }).then((response) => {
            let resVar = response.data
            
        })
    }


    return(
        <div className='practiceTest'>
            
            

            {(currentStep<questionArray.length && currentQuestion!==null) && <QuestionPP 
                question={currentQuestion} 
                optionChosen={questionAnswered}
                step={currentStep}
            />}
            
            {currentStep === questionArray.length &&
                // <span>Complete</span> 
                <TestAnalytics test={test}/>
            }

            <ProgressBarPP 
                progressP={(currentStep/questionArray.length)*100} 
                answered={answered}
                clicked={nextQuestionClicked}
            />
            
        </div>
    )

}
import React, { useEffect, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import '../../../styles/practice.css'
import EditTutorQuestion from './EditTutorQuestion';
import {useNavigate,useLocation,useParams} from 'react-router-dom';
import axios from "axios";
import { auth } from '../../..';


export default function EditTutorTest() {

    const baseURL = process.env.REACT_APP_BASEURL;

    const [testID,setTestID] = useState(Math.random().toString(16).slice(2))
    const [title,setTitle] = useState("")

    const [questions,setQuestions] = useState([])
    const [updateVar,setUpdateVar] = useState(0)

    const [showError,setShowError] = useState(false)
    const [errorMessage,setErrorMessage] = useState(false)
    
    const location = useLocation()

    useEffect(() => {
        
        let test = location.state.test
        if(test !== null)
        {
            setTestID(test.id)
            setTitle(test.name)
            convertQuestions(test.questions)
        }
        
    },[])


    const showQuestions = () => {

        var compArray = []
        var index = 0
        questions.forEach(question => {
            compArray.push(
                <EditTutorQuestion 
                    index={index}
                    questionP={question.question}
                    a1P={question.a1}
                    a2P={question.a2}
                    a3P={question.a3}
                    a4P={question.a4}
                    correctP={question.correct}
                    edit={updateQuestion}
                    deleted={removeQuestion}
                />
            )
            index++
        });

        return compArray

    }


    const addQuestion = () => {
        questions.push({question:"",a1:"",a2:"",a3:"",a4:"",correct:""})
        setQuestions(questions)
        setUpdateVar(updateVar+1)
    }

    
    const updateQuestion = (index,value) => {
        questions[index] = value
        setQuestions(questions)
        setUpdateVar(updateVar+1)
        console.log("QUESTIONS",questions)
    }


    const removeQuestion = (index) => {
        questions.splice(index, 1)
        setQuestions(questions)
        setUpdateVar(updateVar+1)
        console.log("QUESTIONS",questions)
    }


    const saveClick = () => {

        console.log("SAVE CLICKED")
        setShowError(false)

        var index = 0
        var errorFound = false
        var errorField = ""

        if(title===""){errorFound=true; errorField="Test Title"}
        
        questions.forEach(question => {
        
            if(!errorFound)
            {
                if(question.question===""){errorFound=true; errorField="Question"}
                else if(question.a1===""){errorFound=true; errorField="Answer A"}
                else if(question.a2===""){errorFound=true; errorField="Answer B"}
                else if(question.a3===""){errorFound=true; errorField="Answer C"}
                else if(question.a4===""){errorFound=true; errorField="Answer D"}
                else if(question.correct===""){errorFound=true; errorField="Corrent Answer"}

                index++
            }
            
        });

        if(!errorFound)
        {
            console.log("No errors")
            saveTestToDB()
        }
        else{
            setErrorMessage(`Error: ${errorField==="Test Title"?"The":`For Question ${index}, the`} '${errorField}' field is blank`)
            setShowError(true)
        }
    }


    const saveTestToDB = () =>
    {
        let tutorID = auth.currentUser.uid
        axios.post(baseURL+'updatePracticePack',{
            testID:testID,
            questions:questions,
            testTitle:title,
            tutor:tutorID

        }).then((response) => {
            let resVar = response.data
            console.log("RESVAR",resVar)
            
        })
    }




    const convertQuestions = (questionsP) => {

        var questionArray = []
        

        questionsP.forEach(question => {
            let qTitle = question.question
            let a1 = question.answers[0].value
            let a2 = question.answers[1].value
            let a3 = question.answers[2].value
            let a4 = question.answers[3].value

            var correct =""
            if(question.answers[0].correct){correct="A"}
            else if(question.answers[1].correct){correct="B"}
            else if(question.answers[2].correct){correct="C"}
            else if(question.answers[3].correct){correct="D"}

            questionArray.push({question:qTitle,a1:a1,a2:a2,a3:a3,a4:a4,correct:correct})
        });

        setQuestions(questionArray)
        
    }


    return(

        <div className='editTutorScreen'>
            <div className='editTutorTest'>

                <input className='inputAnswer question editTestTitle' type="text" placeholder='Enter Test Name' value={title} onChange={(e) => setTitle(e.target.value)}/>

                {showQuestions()}

                <div className="section1HSBtn discoverTutors" onClick={addQuestion}><span>Add Question</span><BsPlus/></div>

                {showError && <span>{errorMessage}</span>}

                <div className='etqDelete'><button className="saveTestBtn" disabled={questions.length===0} onClick={saveClick}>Save Test</button></div>

            </div>
        </div>
        
    )
}
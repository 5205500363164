import React, { useEffect, useState } from 'react';
import PracticeTestPP from '../components/PracticePlatform/PracticeTestPP';
import {useNavigate,useLocation,useParams} from 'react-router-dom';
import '../styles/practice.css'
import axios from "axios";
import { auth } from '..';

export default function Practice() {

    const [showTest,setShowTest] = useState(false)
    const [selectedTest,setSelectedTest] = useState(null)

    const [packs,setPacks] = useState([])
    const [currentTests,setCurrentTests] = useState([])
    const [completedTests,setCompletedTests] = useState([])

    const baseURL = process.env.REACT_APP_BASEURL;

    const location = useLocation()

    if(selectedTest === null)
    {
        let test = location.state.test
        console.log("TEST SLE",test)
        setSelectedTest(test)
        setShowTest(true)
    }
    
    // useEffect(() => {
    //     getPacks()
    //     getStudentsTests()
    // },[])


    const showPacks = () => {
        var packArray = []
        packs.forEach(pack => {
            packArray.push(<button onClick={()=>createStudentTest(pack.id,pack.name)}>{pack.name}</button>)
        });

        return packArray
    }

    
    const showCurrentTests = () => {
        var testArray = []
        currentTests.forEach(test => {
            testArray.push(<div>
                {test.packName} 
                <progress value={test.progress} max={test.questions.length}></progress>
                <button onClick={()=>continueTest(test)}>Continue</button>
                </div>)
        });

        return testArray
    }

    
    const showCompletedTests = () => {
        var testArray = []
        completedTests.forEach(test => {
            testArray.push(<button onClick={()=>continueTest(test)}>{test.packName}</button>)
        });

        return testArray
    }

    const createStudentTest = (packID,packName) => {
        let studentID = auth.currentUser.uid
        axios.get(baseURL+'createStudentTest',{
            params:{
                packID:packID,
                packName:packName,
                student:studentID
            }
        }).then((response) => {
            let resVar = response.data.test
            currentTests.push(resVar)
            setCurrentTests(currentTests)

            setSelectedTest(resVar)
            setShowTest(true)
        })
    }

    const getPacks = () => {
        axios.get(baseURL+'getPracticePacks',{
            params:{
                business:"Routeen"
            }
        }).then((response) => {
            let resVar = response.data
            setPacks(resVar.packs)
        })
    }

    const getStudentsTests = () => {
        let studentID = auth.currentUser.uid
        axios.get(baseURL+'getTestsForStudent',{
            params:{
                studentID:studentID
            }
        }).then((response) => {
            let resVar = response.data
            let tests = resVar.tests
            let current = []
            let completed = []

            tests.forEach(test => {
                if(test.complete){completed.push(test)}
                else{current.push(test)}
            });

            setCompletedTests(completed)
            setCurrentTests(current)

        })
    }

    const continueTest = (test) => {
        setSelectedTest(test)
        setShowTest(true)
    }


    return(
        <div className='practiceScreen'>
            <div className='practiceContainer'>
                {/* {!showTest && <div className='practiceMainScreen'>
                    <div>
                        <div>Packs: </div>
                        {showPacks()}
                    </div>
                    <div>
                        <div>Current Tests: </div>
                        {showCurrentTests()}
                    </div>
                    <div>
                        <div>Completed Tests: </div>
                        {showCompletedTests()}
                    </div>
                    
                    
                </div>} */}

                {showTest && <PracticeTestPP test={selectedTest} />}
            </div>
            
        </div>
    )

}
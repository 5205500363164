import { useNavigate,useParams } from "react-router-dom";
import ReviewSlider from "../components/Discover/ReviewSlider";
import '../styles/home.css'
import TeacherTile from "../components/Discover/TeacherTile";
import React, { useEffect, useState,useRef } from 'react';
import axios from "axios";
import {BsArrowRight} from "react-icons/bs"
import * as constants from '../constants';

export default function Home({loggedIn}) {

    let { referralID } = useParams();


    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASEURL;

    const [coupon, setCoupon] = useState(null)
    const [couponError, setCouponError] = useState("")
    const [featuredTutors,setFeaturedTutors] = useState([])
    const [featuredLoading,setFeaturedLoading] = useState(true)

    const subjectsRef = React.createRef()

    

    useEffect(() => {
     
        if(constants.MULTIPLE){loadFeaturedTutors()}

        let couponLS = localStorage.getItem("coupon")
        console.log("COUPON LS",couponLS)
        if(couponLS !== null)
        {
            let couponJSON = JSON.parse(couponLS)
            setCoupon(couponJSON)
        }

        if(referralID !== undefined)
        {
            localStorage.setItem("referral",referralID)
            logClickThrough(referralID)
            if(referralID.contains("DIS"))
            {
                searchForCoupon(referralID)
            }
        }

    },[])


// #region DISPLAY COMPONENTS

    const showFeaturedTeachers = () => {
        let featureArray = []
        featureArray.push(<TeacherTile tutor={featuredTutors[0]} homeScreen={true}/>)
        featureArray.push(<TeacherTile tutor={featuredTutors[1]} raised={true} homeScreen={true}/>)
        featureArray.push(<TeacherTile tutor={featuredTutors[2]} homeScreen={true}/>)
        return featureArray
    }

// #endregion


// #region BUTTONS CLICKED

    const startLearningClicked = () =>{
        navigate(`../auth`, { replace: true});
    }

    const goToDiscover = (subject) => {

        if(constants.MULTIPLE)
        {
            if(subject !== null){navigate(`../discover`, { replace: true, state:{subject:subject}});}
            else{
                navigate(`../discover`, { replace: true});
            }
        }
        else{
            navigate(`../tutor`, { replace: true});
        }

        
    }

    const getConsultation = () => {
        const newWindow = window.open('https://calendly.com/raunaqbagga', '_blank', 'noopener,noreferrer')
    	if (newWindow) newWindow.opener = null
    }

// #endregion


// #region WEB REQUESTS

    const logClickThrough = (referralID) => {
            
        axios.get(baseURL+'logReferralClickThrough',{
                params:{
                    referralID:referralID
                }
        }).then((response) => {
            
        })
    }

    const searchForCoupon = (couponID) => {
        
        var uid = "uid"
        
        setCouponError("")
        if(loggedIn)
        {
            let user = JSON.parse(localStorage.getItem("user"))
            uid = user.uid
        }

        
        axios.get(baseURL+'getCoupon',{
            params:{
                couponID:couponID,
                uid:uid,
                business:"Routeen"
            }
        }).then((response) => {
            
            let resVar = response.data
            if(!resVar.error)
            {
                let couponVar = resVar.coupon
                
                localStorage.setItem("coupon",JSON.stringify(couponVar))
                setCoupon(couponVar)

            }
            else{
                setCouponError(resVar.errorMessage)
            }
        })
      
    }

    const loadFeaturedTutors = () => {
        setFeaturedLoading(true)
        axios.get(baseURL+'getFavouriteTutors',{
            
        }).then((response) => {
            let resVar = response.data
            setFeaturedTutors(resVar)
            setFeaturedLoading(false)
            console.log("Featred", resVar)
        })
    }


// #endregion


// #region OTHER

// #endregion

    
const addNewClass = () => {
    axios.post(baseURL+'createTutorClass',{
        id:Math.random().toString(16).slice(2),
        name:"Maths Class",
        startTime: new Date(1681905600000),
        tutorID:"m0YBqV8L5fdzi9H4FzTrEWfkVwL2",
        tutorName:"Caolan Melvin",
        capacity:6

    }).then((response) => {
        
    
    })
}
    
    

    return(
        <div className="homeScreen">

            <div className="homeScreenContainer">
            
            <div className="section1HS">

                <div className="section1HSColumn">
                    <span className="mainTitleHS">The World’s Best SAT Tutors<br /> Are Now Affordable!</span>
                    <span className="mainSubtitleHS">Routeen provides you with the best of teachers from across the globe with a demonstrated history of high scoring students,  starting at only <strong>$9.99/hour</strong>. </span>
                    <div className="section1HSRow">
                        {!loggedIn && <div className="section1HSBtn startLearning" onClick={startLearningClicked}>Start Learning</div>}
                        <div className="section1HSBtn discoverTutors" onClick={()=>goToDiscover(null)}><span>{constants.MULTIPLE?"Meet Our Tutors":"Tutor Profile"}</span><BsArrowRight/></div>
                    </div>
                </div>

                <img className="section1HSimg" src="/assets/homeImg1.png"/>

            </div>


            <div className="section2HS">
                <div className="section2HSRow1">
                    <div className="section2HSIconText"><img className="section2HSimg" src="/assets/orangepeople.png"/><span>1 on 1 Classes</span></div>
                    <div className="section2HSIconText"><img className="section2HSimg" src="/assets/redchecks.png"/><span>Only The Very Best of Tutors</span></div>
                    <div className="section2HSIconText"><img className="section2HSimg" src="/assets/bluewallet.png"/><span>Starting at only $9.99</span></div>
                </div>
                <div className="section2HSRow2">
                    <div className="flexRow">
                        <img className="section2HSPhone" src="/assets/navyPhone.png"/>
                        <span className="section2HStxt">Help us select your ideal tutor, get a <strong>free</strong> 15 minute teacher discovery session</span>
                    </div>
                    <div className="flexRowBtns">
                        <button className="section2HSBtn" onClick={getConsultation}>Book Meeting</button>
                        <button className="section2HSBtn" onClick={addNewClass}>Create Class</button>
                    </div>
                    
                </div>
            </div>



            <div className="section7HS">

                <div className="section7HSColumn">
                    
                    <div className="section7HSinnerColumn">
                        <span>But Wait Early Adopters,</span>
                        <span>There's more!</span>
                    </div>
                    

                    <span className="section7HSsubtext">Get up to <strong>30% off</strong> on your first class for a limited time.</span>
                    

                    {coupon === null && <div className="section7HSbtn" onClick={()=>searchForCoupon("test1")}><span>Apply Coupon</span></div> }
                    {coupon !== null && <div className='bookingCouponInput applied'>
                            {coupon.name} Applied! - {coupon.description}
                        </div>}
                        <span className="section7HSsubtext"></span>

                    <div className="section7HSDisclaimers">
                        <span>*Applied automatically at checkout</span>
                        <span>**Applicable only on 4 and 8 class tier</span>
                    </div>
                    
                </div>

                <img className="section7HSimg" src="/assets/homeImg5.png"/>

            </div>


            <div className="section3HS">
                <img className="section3HSimg1" src="/assets/sec3HSImg1.png"/>
                <img className="section3HSimg1Mobile" src="/assets/sec3HSImg4.png"/>
                <img className="section3HSimg2" src="/assets/sec3HSImg2.png"/>
                <img className="section3HSimg3" src="/assets/sec3HSImg3.png"/>
                <img className="section3HSimg3Mobile" src="/assets/sec3HSImg5.png"/>
            </div>

            

            
            {constants.MULTIPLE && <div className="section4HS">
                <span className="section4HSTitle">Featured teachers of this week</span>
                <span className="section4HSSubTitle">Routeen prides itself on its exceptionally talented pool of tutors and recognises <br/>the work of people who’s contribution has stood out this week</span>
                {featuredLoading && <div>Loading Tutors</div>}
                {!featuredLoading && <div className="featuredTeachersRow">
                    {showFeaturedTeachers()}
                </div>}
                <div id="discoverBtn" className="section1HSBtn discoverTutors"><span id="discoverBtn" onClick={()=>goToDiscover(null)}>Discover Tutors</span><BsArrowRight id="discoverBtn"/></div>
            </div>}


            <div className="section5HS" ref={subjectsRef}>
                <img className="section5HSimg" src="/assets/homeImg3.png"/>

                <div className="section5HSColumn" >
                    <span className="section5HSTitle">Explore Subject Tutor</span>
                    <span className="section5HSSubTitle">Explore our wide variety of individual subject tutors, <br/>Chosen from only the very best</span>
                    <div className="section5HSIconText" onClick={()=>goToDiscover("Maths")}><img className="section5HSicon" src="/assets/mathImg.png"/><span>SAT Math</span></div>
                    <div className="section5HSIconText" onClick={()=>goToDiscover("English")}><img className="section5HSicon" src="/assets/englishImg.png"/><span>SAT English</span></div>

                </div>
            </div>


            <div className="section6HS">
                <div className="section6HSColumn">
                    <span className="section6HSTitle">From the community</span>
                    <span className="section6HSSubTitle">Hear first hand testimonies from past pupils of Routeen's <br/>teachers and be assured what your futur might be!</span>
                    <ReviewSlider />
                </div>
                <img className="section6HSimg" src="/assets/homeImg4.png"/>
            </div>



            </div>

        </div>
    )
}



import { getAuth,signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import '../styles/header.css'
import { auth } from "..";
import * as constants from '../constants'


export default function Header({selectedRouteG,loggedIn,routeChanged,user}) {


    const [selectedRoute,setSelectedRoute] = useState(selectedRouteG)
    const [optionsOpen,setOptionsOpen] = useState(false)
    const [menuOpen,setMenuOpen] = useState(false)
    const [userName, setUserName] = useState(" ")
    const [isTutor,setIsTutor]  = useState(false)
    const [initials,setInitials] = useState("")

    const navigate = useNavigate();   

    
    useEffect(() => {

        if(user !== null)
        {
            setUserName(user.name)
            setInitials(user.name[0])
            setIsTutor(user.isTutor)
        }

    },[user])


    const routeSelected = (route) => {
        setOptionsOpen(false)
        setMenuOpen(false)
        setSelectedRoute(route)
        routeChanged(route)
        navigate(`../${route}`, { replace: true });
    }

    const optionsClicked = () =>{
        setOptionsOpen(!optionsOpen)
    }
    
    const menuClicked = () =>{
        setMenuOpen(!menuOpen)
    }

    const logout = () => {
        // const auth = getAuth();
        setOptionsOpen(false)
        signOut(auth).then(() => {
            // Sign-out successful.
            console.log("SIGN OUT SUCCESS")
            localStorage.removeItem("user")
            localStorage.removeItem("lessons")
            navigate("../auth", { replace: true });
          }).catch((error) => {
            
            console.log("ERROR", error.message)
          });
    }

    useEffect(() => {

        setSelectedRoute(selectedRouteG)

    },[selectedRouteG])


    return(
        <div className="header">

            <div className="headerRow">

                <div className="routesRow">
                    <img className="logoHeader" src="/assets/routeenLogo.png" onClick={()=>routeSelected('')}/>
                    
                    <div className={`headerRoutesRow ${menuOpen?"":"routesClosed"}`}>
                        <span className={`headerRoute ${selectedRoute==''?'selected':''}`} onClick={()=>routeSelected('')}>Home</span>
                        
                        {(!isTutor && constants.MULTIPLE ) && <span className={`headerRoute ${selectedRoute=='discover'?"selected":""}`} onClick={()=>routeSelected('discover')}>Discover Tutors</span>}
                        
                        {(!isTutor && !constants.MULTIPLE ) && <span className={`headerRoute ${selectedRoute=='tutor'?"selected":""}`} onClick={()=>routeSelected('tutor')}>Tutor Profile</span>}
                        
                        
                        {/* <span className={`headerRoute ${selectedRoute=='contact'?"selected":""}`} onClick={()=>routeSelected('contact')}>Contact Us</span> */}
                        
                        {loggedIn && <span className={`headerRoute ${selectedRoute=='dashboard'?"selected":""}`} onClick={()=>routeSelected('dashboard')}>Dashboard</span>}
                        
                        {loggedIn && <span className={`headerRoute auth logout`} onClick={logout}>Sign Out</span>}
                        {(!loggedIn) && <span className={`headerRoute auth logout`}  onClick={()=>routeSelected('auth')}>Login</span>}
                        {(!loggedIn) && <span className={`headerRoute auth`}  onClick={()=>routeSelected('auth')}>Join Now</span>}
                    </div>
                </div>

                


                <div className="buttonsRowHeader">
                    
                    <div className="verticalLine"/>
                    {(!loggedIn) && <button className="headerBtn login" onClick={()=>routeSelected('auth')}>Login</button>}
                    {(!loggedIn) && <button className="headerBtn join">Join Now</button>}
                    

                    {loggedIn && <div className="headerProfile">
                        <div className="headerProfileTitle" onClick={optionsClicked}>
                            <span className="headerProfilePic">{initials}</span>
                            <span>{userName}</span>
                            <img className="headerProfileDown" src="/assets/arrowDown.png"/>
                        </div>
                        
                        {optionsOpen &&<div className="headerProfileOptions" style={{ backgroundImage: "url('//assets/speechBubble.png')" }}>
                            <span className="headerProfileOption" onClick={()=>routeSelected('dashboard')}>Dashboard</span>
                            {/* <span className="headerProfileOption" onClick={()=>routeSelected('contact')}>Contact Us</span> */}
                            <span className="headerProfileOption" onClick={logout}>Sign Out</span>
                        </div>}
                    </div>}

                </div>


                <img  className="headerImg" onClick={menuClicked}  src={menuOpen?"/assets/headerCancel.png":"/assets/headerBurger.png"}/>


                

            </div>


        </div>
    )
}




import React, { useEffect, useState } from 'react';
import '../../styles/practice.css'

export default function ProgressBarPP({progressP,answered,clicked}) {

    const buttonClicked = () => {
        if(answered){
            clicked()
        }
    }

    return(
        <div className='progressBarPP'>

            <div className='progressBarRow'>
                <input className='slider' value={progressP} min="0" max="100" type="range" disabled={true}>
                </input>        
                <div className={`nextQuestionBtnPP ${answered?"":"disabled"}`} onClick={buttonClicked}>
                    <span>NEXT QUESTION</span>
                    <img className='nextQuestionBtnArrow' src='/assets/rightArrow.png'/>
                </div>
            </div>
            <span className='progressText'>{Math.floor(progressP) + "% Complete. Keep it up!"}</span>
            
        </div>
    )

}
import React, { useEffect, useState } from 'react';
import AnswerOptionPP from './AnswerOptionPP';

export default function QuestionPP({question, optionChosen,step}) {

    const [optionSelected,setOptionSelected] = useState(null)
    const [currentStep,setCurrentStep] = useState(null)
    
    useEffect(() => {
        console.log("QUESTION CAHNGED",question)
        setOptionSelected(null)
        setCurrentStep(step)
    },[step])



    const answerClicked = (answer) => {
        setOptionSelected(answer)
        optionChosen(answer)
    }


    


    return(
        <div className='questionPP'>
            
            <span>{question.question}</span>
            {currentStep===step && <div className='answersGridPP'>
                <AnswerOptionPP clicked={answerClicked} answer={question.answers[0]} chosenOption={optionSelected}/>
                <AnswerOptionPP clicked={answerClicked} answer={question.answers[1]} chosenOption={optionSelected}/>
                <AnswerOptionPP clicked={answerClicked} answer={question.answers[2]} chosenOption={optionSelected}/>
                <AnswerOptionPP clicked={answerClicked} answer={question.answers[3]} chosenOption={optionSelected}/>
            </div>}
        </div>
    )

}
import React, { useEffect, useState } from 'react';
import '../../styles/practice.css'
import ProgressBarPP from './ProgressBarPP';
import QuestionPP from './QuestionPP';
import axios from "axios";
import Chart from 'chart.js/auto';
import { max } from 'moment/moment';

export default function TestAnalytics({test}) {


    const [correctAnswers,setCorrect] = useState("")

    const [correctLabels,setCorrectLabels] = useState([])
    const [correctValues,setCorrectValues] = useState([])

    const [inCorrectLabels,setinCorrectLabels] = useState([])
    const [inCorrectValues,setinCorrectValues] = useState([])
    

    
    useEffect(() => {
        if(correctAnswers === ""){calculateTags(test.questions)}
    },[])

    useEffect(() => {
        if(correctAnswers !== ""){
            console.log("HERE NOW", test)
            showCorrect()
            showIncorrect()
        }
        
    },[correctAnswers])


    const calculateTags = (questions) => {

        var correctAnswers = 0
        var correctMap = {}
        var inCorrectMap = {}

        questions.forEach(question => {
            
            let userAnswer = question.answer            
            let questionObj = question.question
            var userCorrect = false

            questionObj.answers.forEach(answer => {
                if(answer.correct && answer.id === userAnswer)
                {
                    userCorrect = true
                }
            });


            if(userCorrect)
            {
                correctAnswers++
                correctMap = updateMap(correctMap,questionObj.tags)
            }
            else{
                inCorrectMap = updateMap(inCorrectMap,questionObj.tags)
            }

        });

        convertMapToArray(correctMap,true)
        convertMapToArray(inCorrectMap,false)

        setCorrect(correctAnswers)
    }


    const updateMap = (mapP,tags) => {

        tags.forEach(tag => {
            var count = 0
            if(mapP.hasOwnProperty(tag))
            {
                count = mapP[tag]
            }
            count++
            mapP[tag] = count
        });

        return mapP
    }

    const convertMapToArray = (mapP,correct) => {

        var keyArray = []
        var valArray = []

        for (let key in mapP) {
            keyArray.push(key)
            valArray.push(mapP[key])
        }

        if(correct){
            setCorrectLabels(keyArray)
            setCorrectValues(valArray)
        }
        else{
            setinCorrectLabels(keyArray)
            setinCorrectValues(valArray)
        }
    }

    const showCorrect = () => {


        const ctx = document.getElementById('myChartCorrect').getContext('2d');
        console.log("DOC",ctx)

        new Chart(ctx, {
            type: 'bar',
            data: {
            labels: correctLabels,
            datasets: [{
                label: 'Attempted Correctly',
                data: correctValues,
                borderWidth: 1,
                backgroundColor:'#96D6A2'
            }]
            },
            options: {
            scales: {
                y: {
                beginAtZero: true
                }
            }
            }
        });
    
    
    }

    
    const showIncorrect = () => {

        const ctx = document.getElementById('myChartIncorrect').getContext('2d');
        console.log("DOC",ctx)

        new Chart(ctx, {
            type: 'bar',
            data: {
            labels: inCorrectLabels,
            datasets: [{
                label: 'Attempted Incorrectly',
                data: inCorrectValues,
                borderWidth: 1,
                backgroundColor:'#FF6961'
            }]
            },
            options: {
            scales: {
                yAxes: [{
                    ticks: {
                        // beginAtZero: true,
                        stepSize: 1,
                    }
                }]
            }
            }
        });
    
    
    }

    

    return(
        <div className='practiceAnalytics'>
            
            <span className='paBold'>{"Pack: "+test.packName}</span>
            <span>{"Total Questions: "+test.questions.length}</span>
            <span>{"Total Correct: "+correctAnswers}</span>
            <span className='paBold'>Tag Insights</span>


            {correctAnswers!== "" && <div className='paChartRow'>
                <div ><canvas className='paChart' id='myChartCorrect' /></div>
                <div><canvas className='paChart' id='myChartIncorrect'/></div>
            </div>}

            

            
        </div>
    )

}